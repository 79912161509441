import React, { useEffect, useState } from "react";
// import i18n from './i18n';
import AppRoutes from "./App.routing";
import "./App.scss";
import { withRouter } from "react-router";
import HeaderSidebarWrapper from "./components/HeaderSidebarWrapper/HeaderSidebarWrapper";
import Toast from "./components/Toast/Toast";
import ToastRight from "./components/ToastRight/ToastRight";
// import GoogleAutocompleteService from './utils/googleAutocompleteService';
// import { loadThirdPartyScript } from './utils/loadThirdPartyScript';
import appEndpoints from "./App.endpoints";
import useApiService from "./services/api.service";
import { useAppContext } from "./AppContext/App.context";
import * as actions from "./AppContext/actions/actions";
import PageLoader from "./components/pageLoader/pageLoader";
import useLocalStorage from "./utils/localStorage";
import { Socket } from "./utils/socket";
import setVariables from "./utils/setVariables";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";
import Header from "./components/Header/Header";
import { preLoginRoutes } from "./constants/preLoginRoutes";
// import useWindowDimensions from "./hooks/useWindowDimensions";
if (process.env.NODE_ENV === "development") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}

function getFaviconEl() {
  return document.getElementById("favicon");
}
function App({ location }: any) {
  const [appData, dispatch]: any = useAppContext();
  const { t, i18n } = useTranslation();
  const [showContent, setShowContent] = useState(true);
  // const { width } = useWindowDimensions();
  // let [, setShowChatDropdown] = useState(false);
  const loginRequired = true;

  const ApiService: any = useApiService();
  let isHeaderSidemenuVisible: any = !preLoginRoutes.includes(
    location.pathname
  );
  const [test, setTest] = useState(!!useLocalStorage.getItem("isSet"));
  const updateFavicon = () => {
    const favicon: any = getFaviconEl();
    if (useLocalStorage.getItem("CustomData")) {
      favicon.href =
        useLocalStorage.getItem("CustomData").navigation_icon ||
        window.location.origin + "/favicon1.svg";
      let favName: any = document.getElementById("favtitle");
      if (useLocalStorage.getItem("CustomData").colors)
        favName.innerHTML =
          useLocalStorage.getItem("CustomData")?.colors.company_name ||
          "Network On";
      else favName.innerHTML = "Network On";
    }
  };
  useEffect(() => {
    setTest(true);
    // if (window.location.pathname === "/login") {
    checkCustomData();
    //  }
    updateFavicon();

    // useLocalStorage.setItem("login_required",true)
    // if(exclusionArray.includes("/checkotp")){
    //   isHeaderSidemenuVisible=false
    // }
    const loginViaAccessToken = async () => {
      const data = {
        access_token: appData.loginData.access_token,
      };
      const res = await ApiService.post(appEndpoints.loginViaAccessToken(data));
      dispatch(actions.loginViaAccessToken(res.data));
    };

    return () => {
      // disconnectSocket();
    };

    // eslint-disable-next-line
  }, []);

  const changeHandler = (ev: any) => {
    i18n.changeLanguage(ev);
  };
  useEffect(() => {});
  let customData: any = {};
  const checkCustomData = () => {
    const domain = new URL(
      process.env.REACT_APP_CUSTOM_URL || "",
      window.location.href
    );
    let configUrl = useLocalStorage.getItem("configUrl");
    const request: any = {
      url:
        window.location.host === "booking.networkon.tk"
          ? "https://demo.networkon.tk/"
          : configUrl
          ? configUrl
          : domain.origin,
      // :"https://booking.topchoicedeliveryja.com/"
      // "https://abc121.networkon.in",
      // "https://bhagsingh.networkon.in",
      // :"https://chetantest.networkon.in"
      // :"https://hbiyih.networkon.in"
      // :"https://chetantest.networkon.in"
      // "https://naman.networkon.in",
      // :"https://filters.networkon.in/"
      // :"https://dpk.networkon.in"
    };
    return ApiService.get(appEndpoints.formSettings(request))
      .then(async (res: any) => {
        console.log(res);
        if (res.message === "success") {
          if (res.data.length > 0) {
            useLocalStorage.setItem("CustomData", res.data[0]);
            if (res.data[0]?.extensions) {
              const activatedExtension = res.data[0]?.extensions.map(
                (element: any) => element.extension_id
              );
              useLocalStorage.setItem("activatedExtension", activatedExtension);
            }
            useLocalStorage.setItem(
              "referenceId",
              res.data[0].customer_reference_id
            );
            customData = useLocalStorage.getItem("CustomData");
            // customData.colors= {"button":"#1394ff"}
            //  appData.formSettings = customData;
            dispatch(actions.onFormSettingResponse(customData));
          } else {
            useLocalStorage.setItem("CustomData", {});

            // useLocalStorage.setItem(
            //   "referenceId",
            //   res.data[0].customer_reference_id
            // );
            customData = {};
            // customData.colors= {"button":"#1394ff"}
            //  appData.formSettings = customData;
            dispatch(actions.onFormSettingResponse(customData));
          }
          // getTheme();
        }
        console.log(customData);
        updateFavicon();
        return true;
      })
      .catch((err: any) => {});
  };
  const getTheme = () => {
    let themeColor = "";
    if (
      appData.formSettings &&
      appData.formSettings.colors &&
      appData.formSettings.colors.button
    ) {
      themeColor = appData.formSettings.colors.button;
      setVariables(appData.formSettings.colors);
    }
    return createTheme({
      // typography: {
      //   fontFamily: "Lato",
      // },
      palette: {
        primary: {
          main: themeColor ? themeColor : "#007ebf",
          dark: themeColor ? themeColor : "#007ebf",
          light: themeColor ? themeColor : "#007ebf",
        },
        secondary: {
          main: "#ffffff",
          dark: themeColor ? themeColor : "#007ebf",
          light: themeColor ? themeColor : "#007ebf",
        },
      },
    });
  };
  //

  const isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
      return (
        isMobile.Android() ||
        isMobile.BlackBerry() ||
        isMobile.iOS() ||
        isMobile.Opera() ||
        isMobile.Windows()
      );
    },
  };

  useEffect(() => {
    const { screen } = window;
    window.addEventListener("orientationchange", async () => {
      if (isMobile.Android()) {
        if (screen.availWidth > screen.availHeight) {
          setShowContent(false);
          alert(
            "Landscape mode is currently not supported. Please switch to Portrait mode!"
          );
          return;
        }
      }
      setShowContent(true);
    });

    if (isMobile.iOS()) {
      if (window.innerWidth) {
        if (window.innerWidth < 821) {
          window.addEventListener("resize", () => {
            setTimeout(() => {
              if (
                window.matchMedia("(orientation: landscape)").matches === true
              ) {
                setShowContent(false);
                alert(
                  "Landscape mode is currently not supported. Please switch to Portrait mode!"
                );
                return;
              }
              setShowContent(true);
            }, 500);
          });
        }
      }
    }
  }, []);

  const checkLandScape = async () => {};

  return (
    <MuiThemeProvider theme={getTheme()}>
      <div
        className={isHeaderSidemenuVisible ? "app" : ""}
        style={
          !showContent ? { visibility: "hidden" } : { visibility: "visible" }
        }
      >
        {appData.onLoaded?.showLoader ? <PageLoader /> : null}
        {window.location.href.indexOf("checkotp") < 0 ? (
          isHeaderSidemenuVisible ? (
            <HeaderSidebarWrapper></HeaderSidebarWrapper>
          ) : null
        ) : (
          ""
        )}

        <div className={isHeaderSidemenuVisible ? "app__outer" : ""}>
          <AppRoutes></AppRoutes>
        </div>
        <Toast></Toast>
      </div>
    </MuiThemeProvider>
  );
}
//
App.whyDidYouRender = true;

export default withRouter(App);
